import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React from 'react';
import { getQuiltWrapperId } from '../../pageeditor/PageEditorQuiltWrapperDuplicateModal/useQuiltWrapperToDuplicate';
import type { BannerWidgetProps } from './types';
import useEndUserPageDescriptor from '../../useEndUserPageDescriptor';

const EditableBannerWidget = dynamic(() => import('./EditableBannerWidget'));

/**
 * Banner widget defined in Page Descriptor to be rendered inside a quilt header specified in quilt wrapper
 *
 * @author Luisina Santos, Jonathan Bridges
 */
const BannerWidget = <TypeT extends BannerWidgetProps>({
  fontColor,
  backgroundImageProps,
  backgroundColor,
  backgroundOpacity,
  visualEffects,
  className,
  ...restProps
}: TypeT) => {
  const { bannerWidget: ComponentElement } = useEndUserPageDescriptor();
  const { router } = useEndUserRoutes();
  const quiltWrapperId: string = router.getUnwrappedQueryParam(
    EndUserQueryParams.QUILT_WRAPPER_ID,
    null
  );
  const templateId: string = router.getUnwrappedQueryParam(
    EndUserQueryParams.QUILT_WRAPPER_TEMPLATE_ID,
    null
  );

  if (!!quiltWrapperId) {
    return (
      <EditableBannerWidget
        fontColor={fontColor}
        backgroundImageProps={backgroundImageProps}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        visualEffects={visualEffects}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
        quiltWrapperId={getQuiltWrapperId(quiltWrapperId, templateId)}
      />
    );
  }

  return (
    !!ComponentElement && (
      <ComponentElement
        fontColor={fontColor}
        backgroundImageProps={backgroundImageProps}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        visualEffects={visualEffects}
        className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    )
  );
};
export default BannerWidget;
